import { Injectable } from "@angular/core";
import Typo from "typo-js";
import { Language } from "../types/bced";

export enum DictionaryLanguage {
    English = 'en_US',
    French = 'fr',
}

export interface Alert {
    readonly id: string;
    readonly word: string;
    readonly startOffset: number;
    readonly endOffset: number;
}

export interface AlertV2 {
    readonly word: string;
    readonly startNode: Node;
    readonly startNodeOffset: number;
    readonly endNode: Node;
    readonly endNodeOffset: number;
}

export interface Highlight {
    // id: string;
    top: number;
    left: number;
    width: number;
    height: number;
}

export interface Offsets {
    top: number;
    bottom: number;
    left: number;
    right: number;
}

export interface CheckOptions {
    language?: Language;
}

export interface CalculateHighlightsOptions {
    mode: 'ckeditor5';
}

export interface SpellCheckResult {
    word: string;
    top: number;
    left: number;
    width: number;
    height: number;
    // color: string;
}

@Injectable({
    providedIn: 'root'
})
export class SpellCheckService {
    private dictionary: { [key in Language]?: any } = {};
    private readonly dictionaryPath: string = 'assets/dictionaries';
    private readonly wordRegExp: RegExp = /\b[a-zA-Z]+\b/g;
    private readonly TextNodeType: number = 3;
    private readonly Punctuations = " !\"#$%&'()*+,./:;<=>?@[\\]^_`{|}~«»";
    private currentWord = '';
    private startNode = null;
    private startNodeOffset = null;
    private endNode = null;
    private alerts: AlertV2[] = [];
    private highlights: Highlight[] = [];

    constructor() {
        
    }

    public init(language: Language) {
        const dl: DictionaryLanguage = this.getDictionaryLanguage(language);
        this.dictionary[language] = new Typo(dl, false, false, { dictionaryPath: this.dictionaryPath });

    }

    private getDictionaryLanguage(l: Language): DictionaryLanguage | null {
        switch(l) {
            case Language.English:
                return DictionaryLanguage.English;
            case Language.French:
                return DictionaryLanguage.French;
            default:
                return null;
        }
    }

    public checkText(text: string, options?: CheckOptions): Alert[] {
        const { 
            language = Language.English,
        } = options || {};

        if (!this.dictionary[language]) return [];

        const alerts = [];

        let id = 0;
        let match: RegExpExecArray | null = null;
        while ((match = this.wordRegExp.exec(text)) !== null) {
            const word: string = match[0];

            if (!this.dictionary[language].check(word)) {
                const startIndex = match.index;
                const endIndex = startIndex + word.length;

                alerts.push({
                    id: `${id++}`,
                    word,
                    startOffset: startIndex,
                    endOffset: endIndex,
                });
            }
        }

        return alerts;
    }

    public checkWord(word: string, options?: CheckOptions): boolean {
        const { 
            language = Language.English,
        } = options || {};

        if (!this.dictionary[language]) return false;

        return this.dictionary[language].check(word);
    }

    public generateCKEditor5Highlights(node: HTMLElement, l: Language): Highlight[] {
        // CKEditor5: the element with class ".ck-content" is the one wraps all <p> tags
        const contentNode: Element = node.querySelector('.ck-content');
        if (!contentNode) return [];
        if (!contentNode.hasChildNodes()) return [];

        // the parent container
        const ckContentRect = contentNode.getClientRects()[0];

        this.calculateHighlightsV4(contentNode, ckContentRect, l);

        return this.highlights;
    }

    private resetState() {
        this.alerts = [];
        this.highlights = [];
        this.currentWord = '';
        this.startNode = null;
        this.startNodeOffset = null;
        this.endNode = null;
    }

    private calculateHighlightsV4(node: Node, containerRect: DOMRect, l: Language) {
        this.resetState();
        this.scanWords(node, l);

        if (!this.alerts.length) return;

        for (const alert of this.alerts) {
            const r = document.createRange();
            r.setStart(alert.startNode, alert.startNodeOffset);
            r.setEnd(alert.endNode, alert.endNodeOffset > 0 ? alert.endNodeOffset : alert.endNode.textContent.length);
            const rangeSpec = r.getBoundingClientRect();

            this.highlights.push({
                top: rangeSpec.top - containerRect.top + rangeSpec.height,
                left: rangeSpec.left - containerRect.left,
                width: rangeSpec.width,
                height: 2,
            });
        }
    }

    private scanWords(node: Node, l: Language): void {
        if (node.nodeType === this.TextNodeType) {
            this.scanText(node, l);
            return;
        }

        if (node.hasChildNodes()) {
            for (const child of Array.from(node.childNodes)) {
                this.scanWords(child, l);
            }
        }
    }

    private scanText(node: Node, l: Language): void {
        if (node.textContent.trim()) {
            this.endNode = node;
        }

        for (let i = 0; i < node.textContent.length; i++) {
            if (this.Punctuations.includes(node.textContent[i])) {
                if (this.currentWord.length > 0) {
                    if (!this.checkWord(this.currentWord, {
                        language: l,
                    })) {
                        this.alerts.push({
                            word: this.currentWord,
                            startNode: this.startNode,
                            startNodeOffset: this.startNodeOffset,
                            endNode: this.endNode,
                            endNodeOffset: node.isSameNode(this.endNode) ? i : this.endNode.textContent.length,
                        });
                    }
                    
                    this.currentWord = '';
                    this.startNode = null;
                }
            } else {
                if (!this.currentWord.length) {
                    this.startNode = node;
                    this.startNodeOffset = i;
                }
                this.currentWord += node.textContent[i];
            }
        }
    }
}

